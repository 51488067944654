import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PixIcon from '@mui/icons-material/Pix';
import { MyMenu } from "./MyMenu/MyMenu";
import MyBtn from "../../UI/MyBtn/MyBtn";
import { MobileMenu } from "./MobileMenu/MobileMenu";
import { Link } from 'react-router-dom';
import { Avatar, Badge, Theme, useMediaQuery } from "@mui/material";
import avatar_img from "../../../public/avatar.png";
import { ProfileMenu } from "./ProfileMenu/ProfileMenu";
import { useSelector } from 'react-redux';
import { RootState } from "../../../store/store";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useState, MouseEvent } from 'react';
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from 'react-i18next';
import useChangeLanguage from "../../../hooks/useChangeLanguage";
import PinDropIcon from '@mui/icons-material/PinDrop';
import { styled } from '@mui/system';
import imgIcon from '../../../public/main/R.svg'

export const NavBar = () => {
    const { t, i18n } = useTranslation();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    const pages = [

        {
            id: '/about',
            name: t('navBar.about'),
            path: `${i18n.language}/about`,
        },
        {
            id: `/pricing`,
            name: t('navBar.pricing'),
            path: `${i18n.language}/pricing`,
        },
        {
            id: '/FAQ',
            name: t('navBar.faq'),
            path: `${i18n.language}/FAQ`,
        },
        {
            id: '/rascoin',
            name: `RASCOIN`,
            path: `${i18n.language}/rascoin`,
        },
        {
            id: '/pros',
            name: t('navBar.pros'),
            path: `${i18n.language}/pros`,
            click: true,
        }
    ];
    const HeartbeatPinDropIcon = styled('div')`
        @keyframes heartbeat {
            0% {
            transform: scale(1);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }
            50% {
            transform: scale(1.1);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            }
            100% {
            transform: scale(1);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }
        }

        animation: heartbeat 1.5s infinite;
        border-radius: 50%;
        `;

    const { isAuthenticated, role } = useSelector((state: RootState) => state.auth);
    const { isPro } = useSelector((state: RootState) => state.auth);
    const { notificationSource } = useSelector((state: RootState) => state.messageSource)

    const { dataProfile } = useSelector((state: RootState) => state.profile);

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [openProfileMenu, setOpenProfileMenu] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenProfileMenu = (event: MouseEvent<HTMLElement>) => {
        if (openProfileMenu === null) {
            setOpenProfileMenu(event.currentTarget);
        } else {
            setOpenProfileMenu(null);
        }
    };

    const changeLanguage = useChangeLanguage();

    return (
        <AppBar position="sticky"
            sx={{
                backgroundColor: '#ffffff',
                boxShadow: 'none'
            }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to={`/${i18n.language}`} style={{ textDecoration: 'none' }}>
                        <img
                            src={imgIcon} // Replace with your image URL
                            alt="icon"
                            style={{
                                width: 34,
                                height: 34, // Matches MUI icon size
                                // marginTop: '6px',
                            }}
                        />
                    </Link>
                    {(!isAuthenticated || !isSmallScreen) &&
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href={`/${i18n.language}`}
                            sx={{
                                mr: 2,
                                // ml: 1,
                                display: 'flex',
                                fontFamily: 'monospace',
                                scale: '1.2',
                                fontWeight: 700,
                                color: '#02A535',
                                // letterSpacing: '.3rem',
                                textDecoration: 'none',
                            }}
                        >
                            {/* <PixIcon /> */}
                            astcom
                        </Typography>
                    }
                    {isSmallScreen && <Box sx={{ marginLeft: '50px' }}>
                        <Link to={pages[4].path} key={pages[4].id}>
                            {isAuthenticated ?
                                <PinDropIcon sx={{ fontSize: 40 }} color='success'></PinDropIcon>
                                :
                                <HeartbeatPinDropIcon>
                                    <PinDropIcon sx={{ fontSize: 40 }} color='success'></PinDropIcon>
                                </HeartbeatPinDropIcon>
                            }
                        </Link>
                    </Box>}
                    <MyMenu />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <MobileMenu pages={pages} anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu} />
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                        alignItems: "center"
                    }}>
                        {pages.map((page) => (
                            <Link to={page.path} key={page.id}>
                                <Button
                                    variant={page.id === '/rascoin' ? 'outlined' : undefined}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                    }}
                                    sx={{
                                        my: 2,
                                        color: '#6B7280',
                                        display: 'block',
                                        textTransform: 'capitalize',
                                        marginTop: page.id === '/pros' ? '0.5rem' : undefined,
                                    }}
                                >
                                    {page.name}{(page.id === '/pros') && <PinDropIcon sx={{ paddingTop: 0, marginTop: 0 }} />}
                                </Button>
                            </Link>
                        ))}
                        {
                            role === "admin"
                                ?
                                <Link to={`${i18n.language}/admin`}>
                                    <MyBtn mobile={false}>
                                        admin
                                    </MyBtn>
                                </Link>
                                :
                                null
                        }
                    </Box>
                    {
                        isAuthenticated ?
                            <>
                                <Link to={`${i18n.language}/notification`}>
                                    <Badge badgeContent={notificationSource} color="secondary" sx={{
                                        '& .MuiBadge-badge': {
                                            right: 6,
                                            top: 2,
                                        }
                                    }}>
                                        <NotificationsIcon sx={{ color: "#1e905d", marginRight: 2 }} />
                                    </Badge>
                                </Link>
                                <Button
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleOpenProfileMenu}
                                    endIcon={openProfileMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                >
                                    <Badge
                                        badgeContent={isPro ? <VerifiedIcon sx={{ color: "#1e905d" }} /> : null}
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                right: -5,
                                                top: 2,
                                            }
                                        }}
                                    >
                                        <Avatar
                                            alt={"name"}
                                            src={dataProfile?.userPhoto !== null ? dataProfile?.userPhoto : avatar_img}
                                            sx={{ width: 35, height: 35 }}
                                        />
                                        <ProfileMenu openProfileMenu={openProfileMenu}
                                            setOpenProfileMenu={setOpenProfileMenu} />
                                    </Badge>
                                </Button>
                            </>
                            :
                            <>
                                <Link to={`${i18n.language}/login`}>
                                    <Button variant="outlined"
                                        sx={{
                                            color: '#000000',
                                            border: '1px solid #D1D5DB',
                                            textTransform: 'capitalize',
                                            margin: 2,
                                            fontSize: 12,
                                            display: { xs: 'none', md: 'flex' }
                                        }}
                                    >
                                        {t('navBar.signIn')}
                                    </Button>
                                </Link>
                                <Link to={`${i18n.language}/signup`}>
                                    <MyBtn mobile={true}>
                                        {t('navBar.singUp')}
                                    </MyBtn>
                                </Link>
                            </>
                    }
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <IconButton
                            onClick={changeLanguage}
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            sx={{
                                color: '#374151',
                                display: { xs: 'none', md: 'flex' },
                                fontSize: "13px",
                                padding: '13px',
                            }}
                        >
                            <LanguageOutlinedIcon />
                            {i18n.language === "en" ? 'FR' : "EN"}
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{
                                color: '#9CA3AF',
                                display: { xs: 'flex', md: 'none' }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
