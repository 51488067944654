import React, { FC } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ImgLoading } from "../../../../component/UI/ImgLoading/ImgLoading";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ListPaymentType {
    header: string
    description: string
    img: string
}

export const ListPayment: FC<ListPaymentType> = ({ header, description, img }) => {
    const { t, i18n } = useTranslation();
    return (
        <motion.div
            initial={{
                scale: .7
            }}
            whileInView={{
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{ once: true }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: { xs: 'center', md: 'space-around' },
                alignItems: "center",
                flexDirection: { xs: 'column', md: 'row' },
                width: "90%",
                margin: 'auto'
            }}>
                <Box>
                    <Link to={`/${i18n.language}/rascoin`}>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#32325d" }}>
                            {header}
                        </Typography>
                    </Link>
                    <Typography
                        sx={{
                            fontSize: 15,
                            color: "#6B7280"
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box sx={{
                    minWidth: { xs: '90vw', md: '60%' },
                    height: "400px",
                    paddingLeft: { xs: '0', md: '50px' },
                }}>
                    <ImgLoading src={img} alt={"crypto"} />
                </Box>
            </Box>
        </motion.div>
    );
};
