import React from 'react';
import { Card, CardContent, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Ras_Pros from "../../public/main/rasMap.png"
import Ras_Pros_Mobile from "../../public/main/rasMapMobile.png"
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import { WhyWe } from './WhyWe/WhyWe';
import { Helmet } from 'react-helmet';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import EasOfUse from './EasOfUse/EasOfUse';
import RascoinHome from './RascoinHome/RascoinHome';
import Ai from './AI/Ai';
import ForBusiness from './EasOfUse/ForBusiness/ForBusiness';
import { ComparisonHome } from './ComparisonHome/ComparisonHome';
import AffiliateProgramInfo from './Affiliate/Affiliate';

export const Home = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('main.tags.homeTitle')}</title>
                <meta name="description" content={t('main.tags.homeDescription')} />
            </Helmet>
            <Grid container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: "#F9FAFB",
                }}
            >
                <Grid
                    item
                    xs={11}
                    md={5}
                    lg={5}
                    sx={{
                        position: "relative", // Make the container relative to position the child absolutely
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        height: { xs: '350px', md: 'auto' },
                        borderRadius: "5px",
                        overflow: "hidden",
                        margin: "0px 15px",
                        paddingTop: '30px',
                    }}
                >
                    {/* First image */}
                    <img src={Ras_Pros} alt='raspros'
                        style={{
                            position: 'absolute',
                            right: '-70%',
                            width: '150%',
                            overflow: "hidden",
                            height: 'auto',
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                            padding: '10px',
                            borderRadius: '10px'
                        }}
                    />
                    {/* Second image */}
                    <img
                        src={Ras_Pros_Mobile}
                        alt='raspros mobile'
                        style={{
                            position: "absolute", // Position the second image absolutely
                            bottom: '40%', // Place the second image at the bottom
                            left: 0, // Place the second image at the left
                            transform: "translate(2%, 50%)", // Adjust to position at the left bottom corner
                            width: '37%', // Set the width of the second image
                            height: 'auto', // Maintain aspect ratio
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                            padding: '10px',
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        }}
                    />
                </Grid>
                <Grid item md={6} sm={12} xs={12}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: "15px",
                    }}
                >
                    <Card sx={{ maxWidth: { xs: '100vw', md: '50vw', margin: "10px", background: '#F9FAFB', borderRadius: '20px' } }}>
                        <CardContent>
                            <Divider sx={{ color: '#FF7600', borderRadius: "10px 10px 0 0", }} role="presentation">
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: '#32325d',
                                        marginBottom: '10px',
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out',
                                    }}
                                >
                                    {t('main.banner.titleSecond')}
                                </Typography>
                            </Divider>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    color: "#6B7280"
                                }}
                            >
                                {t('main.banner.descriptionSecond')}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: { xs: '100vw', md: '50vw', margin: "10px", background: '#F9FAFB', borderRadius: '20px' } }}>
                        <CardContent>
                            <Divider sx={{ color: '#FF7600', borderRadius: "10px 10px 0 0", }} role="presentation">
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: '#32325d',
                                        marginBottom: '10px',
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out',
                                    }}
                                >
                                    {t('main.banner.titleFirst')}</Typography>
                            </Divider>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    color: "#6B7280"
                                }}
                            >
                                {t('main.banner.descriptionFirst')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>



            </Grid>
            <ForBusiness />
            <AffiliateProgramInfo />
            <ComparisonHome />
            <Ai />
            {/* <RascoinHome /> */}
            <EasOfUse />
            <WhyWe />
            <PaymentMethods />
            {/* <ReferAndEarn/>  */}
            {/* <FaqHome/> */}
        </>
    )
}