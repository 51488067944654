import * as React from 'react';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import AppsIcon from '@mui/icons-material/Apps';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CircularProgress, Divider, Grid, List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import { AndroidApp } from "../../../../public/svg/AndroidApp";
import { IosApp } from "../../../../public/svg/IosApp";
import { MenuLink } from '../../../UI/MenuLink/MenuLink';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { setProsCatFooter } from "../../../../store/userPros/userProsSlice";
import { useTranslation } from "react-i18next";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BuildIcon from '@mui/icons-material/Build';



export const MyMenu = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { responseCategoryCity, loadingPros } = useSelector((state: RootState) => state.pros)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box sx={{
            display: { xs: 'none', md: 'flex', }
        }}>
            {open && <Box sx={{
                position: 'fixed',
                top: 100,
                left: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(5px)',
                zIndex: 1,
            }}
            />}
            <Button
                onClick={handleClick}
                sx={{
                    color: "#6B7280",
                    textTransform: 'capitalize',
                }}
            >
                <AppsIcon />
                <Typography variant="body2">{t('navBar.services')}</Typography>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                aria-labelledby="basic-demo-button"
                sx={{
                    '.MuiPaper-root': {
                        backgroundColor: "#f3f3f8",
                        width: '100%',
                        maxWidth: '100%',
                        marginLeft: '15px',
                        marginTop: "15px",

                        borderRadius: "0px 0px 35px 35px",
                        padding: 3
                    }
                }}
            >
                <Grid container spacing={2} sx={{
                    display: "flex",
                    justifyContent: "space-around",
                }}>
                    <List
                        subheader={
                            <ListSubheader
                                sx={{ fontWeight: 600, fontSize: "17px", marginBottom: "-5px;", marginTop: "1px", color: "#9CA3AF", backgroundColor: "#f3f3f8", borderBottom: "solid", borderTop: "solid rgba(0, 0, 0, 0.07)", paddingBottom: 0 }}
                                component="div" id="list-subheader">
                                {t('footer.blockLinkSecond.categories')}
                            </ListSubheader>
                        }
                        sx={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            boxShadow: 'inset 0 0 3px rgba(0, 0, 0, 0.2)',
                            paddingTop: 0,
                            paddingRight: 3,
                            paddingBottom: 3,
                            paddingLeft: 3,
                        }}
                    >
                        {loadingPros && <CircularProgress />}
                        {responseCategoryCity?.categories?.map((service: any) => (
                            <Link
                                key={service}
                                to={`${i18n.language}/pros`}
                                onClick={() => {
                                    dispatch(setProsCatFooter(service))
                                    setAnchorEl(null);
                                }}
                            >
                                <ListItem sx={{ color: "#6B7280", fontSize: 14, }}>
                                    {service}
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px solid #e5e7eb",
                            borderRadius: "10px",
                            padding: "20px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            textAlign: "center",
                            maxHeight: "200px"
                        }}
                    >
                        <Link to={`/${i18n.language}/contact-us`} style={{ textDecoration: "none", color: "inherit", }} onClick={handleClose}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "15px",
                                }}
                            >
                                <MonetizationOnIcon sx={{ fontSize: 24, marginRight: "8px" }} />
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    {t('navBar.affiliateTitle')}
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{ color: "#6b7280", maxWidth: "300px" }}
                            >
                                {t('navBar.affiliateBody')}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            border: "1px solid #E5E7EB",
                            borderRadius: "5px",
                            padding: "10px",
                            maxHeight: "200px"
                        }}
                    >
                        <Link to={`${i18n.language}/FAQ`} onClick={handleClose}>
                            <MenuLink text="FAQ" />
                        </Link>
                        <Link to={`${i18n.language}/contact-us`} onClick={handleClose}>
                            <MenuLink text={`${t('navBar.contactUs')}`} />
                        </Link>
                        <Divider />
                        <Typography sx={{ px: 2, fontSize: "12px", color: "#6B7280" }}>{i18n.language === "en" ? '(Coming soon...)' : "(A venir...)"}</Typography>
                        <Grid item sx={{
                            display: "flex",
                            marginTop: "10px",
                        }}>
                            <Box sx={{ marginRight: "15px" }}>
                                <AndroidApp />
                            </Box>
                            <IosApp />
                        </Grid>
                    </Grid>
                </Grid>
                <Box>
                    <Box mt={3} mb={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider sx={{ flexGrow: 1 }} />
                        <Typography sx={{ px: 2, fontSize: "16px", color: "#6B7280" }}>{t('navBar.corporateClients')}</Typography>
                        <Divider sx={{ flexGrow: 7 }} />
                    </Box>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px solid #e5e7eb",
                            borderRadius: "10px",
                            padding: "20px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            textAlign: "center",
                            maxHeight: "200px",
                        }}
                    >
                        <Link
                            to={`${i18n.language}/contact-us`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            onClick={handleClose}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "15px",
                                }}
                            >
                                <CorporateFareIcon sx={{ fontSize: 24, marginRight: "8px" }} />
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    {t('navBar.corporate')} - {t('navBar.contactUs')}
                                </Typography>
                            </Box>
                            <List sx={{ padding: 0 }}>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <>
                                                <SupportAgentIcon
                                                    sx={{ fontSize: 20, color: "#6b7280", marginRight: "8px" }}
                                                />
                                                {i18n.language === "en"
                                                    ? "Demo products of interest."
                                                    : "Démonstration des produits d'intérêt"}
                                            </>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <>
                                                <LocalOfferIcon
                                                    sx={{ fontSize: 20, color: "#6b7280", marginRight: "8px" }}
                                                />
                                                {i18n.language === "en"
                                                    ? "Flexible payment methods"
                                                    : "Modes de paiement flexibles"}
                                            </>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <>
                                                <BuildIcon
                                                    sx={{ fontSize: 20, color: "#6b7280", marginRight: "8px" }}
                                                />
                                                {i18n.language === "en"
                                                    ? "Get started faster with dedicated implementation and support services."
                                                    : "Démarrez plus rapidement avec des services dédiés d'implémentation et de support."}
                                            </>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Link>
                    </Grid>
                </Box>
            </Menu>
        </Box >
    );
}
